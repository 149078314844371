// src/components/Scenarios.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import './Scenarios.css';

const Scenarios = () => {
  const { t } = useTranslation();

  return (
    <section id="scenarios" className="scenarios">
      <div className="scenarios-content">
        <h2>{t('scenarios')}</h2>
        <p>{t('scenarios_description')}</p>
        <div className="scenarios-grid">
          <div className="scenario-card">
            <img src="/images/restaurant.jpg" alt="Restaurant" />
            <h3>{t('restaurant')}</h3>
          </div>
          <div className="scenario-card">
            <img src="/images/spa.jpg" alt="Spa" />
            <h3>{t('spa')}</h3>
          </div>
          <div className="scenario-card">
            <img src="/images/dental-clinic.jpg" alt="Dental Clinic" />
            <h3>{t('dental_clinic')}</h3>
          </div>
          <div className="scenario-card">
            <img src="/images/florist.jpg" alt="Florist" />
            <h3>{t('florist')}</h3>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Scenarios;
