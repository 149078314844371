// src/components/Banner.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import './Banner.css';

const Banner = () => {
  const { t } = useTranslation();

  return (
    <section className="banner">
      <div className="banner-content">
        <h1>{t('ai_service')}</h1>
        <p>{t('ai_service_description')}</p>
      </div>
    </section>
  );
};

export default Banner;
