// src/components/Footer.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import './Footer.css';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer>
      <ul>
        <li><a href="#about">{t('footer_about')}</a></li>
        <li><a href="#careers">{t('footer_careers')}</a></li>
        <li><a href="#contact">{t('footer_contact')}</a></li>
      </ul>
      <p>© 2024 MJ AI Solution. {t('footer_rights')}</p>
    </footer>
  );
};

export default Footer;
