// src/components/Ending.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import './Ending.css';

const Ending = () => {
  const { t } = useTranslation();

  return (
    <section id="ending" className="ending">
      <div className="image-container">
        <div className="overlay">
          <div className="ending-content">
            <h2>{t('ending_section_title')}</h2>
            <p>{t('ending_section_content')}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Ending;
