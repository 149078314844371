import React from 'react';
import Header from './components/Header';
import Banner from './components/Banner';
import AIService from './components/AIService';
import AppointmentOrder from './components/AppointmentOrder';
import Scenarios from './components/Scenarios';
import Integration from './components/Integration';
import Testimonials from './components/Testimonials';
import CostSaving from './components/CostSaving';
import Ending from './components/Ending';
import Footer from './components/Footer';
import './App.css';

function App() {
  return (
    <div>
      <Header />
      <Banner />
      <AIService />
      <AppointmentOrder />
      <Scenarios />
      <Integration />
      <CostSaving/>
      <Ending/>
      {/*<Testimonials />*/}
      <Footer />
    </div>
  );
}

export default App;
