// src/components/CostSaving.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import './CostSaving.css';

const CostSaving = () => {
  const { t } = useTranslation();

  return (
    <section id="cost-saving" className="cost-saving">
      <div className="cost-saving-content">
        <h2>{t('cost_saving_section_title')}</h2>
        <p>{t('cost_saving_section_content')}</p>
      </div>
      <div className="image-container">
        <img src="/images/cost-saving.jpg" alt="Cost Saving" className="section-image" />
      </div>
    </section>
  );
};

export default CostSaving;
