// src/components/Header.js
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Header.css';

const Header = () => {
  const { t, i18n } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="header">
      <div className="header-left">
        <div className="company-name">
          MJ <span className="ai-highlight">AI</span>
        </div>
        <div className="language-switcher">
          <button onClick={() => changeLanguage('en')}>English</button>
          <button onClick={() => changeLanguage('zh')}>中文</button>
        </div>
      </div>
      <button className="menu-toggle" onClick={toggleMenu}>
        <span className="menu-icon">&#9776;</span> {/* 3 horizontal lines */}
      </button>
      <nav className={menuOpen ? 'nav-open' : ''}>
        <ul>
          <li><a href="#ai-service">{t('ai_service')}</a></li>
          <li><a href="#appointment-order">{t('appointment_order')}</a></li>
          <li><a href="#scenarios">{t('scenarios')}</a></li>
          <li><a href="#integration">{t('integration')}</a></li>
          {/*<li><a href="#testimonials">{t('testimonials')}</a></li>*/}
          <li><a href="#cost_saving_section_title">{t('cost_saving_section_title')}</a></li>
          <li><a href="#ending_section_title">{t('ending_section_title')}</a></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
