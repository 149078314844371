// src/components/AIService.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import './AIService.css';

const AIService = () => {
  const { t } = useTranslation();

  return (
    <section id="ai-service" className="ai-service">
      <div className="ai-service-content">
        <div className="ai-service-text">
          <h2>{t('ai_service')}</h2>
          <p>{t('ai_service_description')}</p>
        </div>
        <div className="ai-service-image">
          <img src="/images/ai-service.jpg" alt="AI Service" />
        </div>
      </div>
    </section>
  );
};

export default AIService;
