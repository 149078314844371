// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          "header": "Your Logo",
          "ai_service": "AI-Powered Customer Service",
          "ai_service_description": "Revolutionize your customer experience with our AI-powered customer service. Our advanced AI handles customer calls with ease, providing human-like responses, 24/7. Whether it’s answering queries or assisting with tasks, our AI is always ready to help. Engage in real-time chat to provide the best service, no matter the medium.",
          "appointment_order": "AI-Driven Appointments & Orders",
          "appointment_order_description": "Enhance your business operations with AI-driven appointment scheduling and order management. Allow your customers to seamlessly book appointments, order food, and manage their interactions through an intuitive interface. Whether it's by phone or online, our AI ensures a smooth process. Log in to our website to view, modify, and oversee all customer engagements.",
          "scenarios": "Versatile AI for Multiple Scenarios",
          "scenarios_description": "Our AI customer service solution is adaptable to various scenarios, from restaurants to spas, dental clinics to florist shops. No matter the industry, our AI seamlessly integrates with your existing systems, providing personalized, efficient, and consistent service that meets your unique needs.",
          "integration": "Seamless Integration and Customization",
          "integration_description": "Integrate our AI customer service with your existing software and customize it to match your brand's voice. Whether you need to connect with your CRM, manage customer data, or offer tailored services, our API makes it easy to integrate and extend functionality. Your brand, your rules, powered by our AI.",
          "testimonials": "Customer Testimonials and Success Stories",
          "testimonials_description": "Hear from our satisfied clients who have transformed their customer service with our AI solutions. From increased efficiency to enhanced customer satisfaction, our AI has delivered measurable results across various industries. Read their stories and see how our AI can work for you.",
          "cost_saving_section_title": "How Our AI Customer Service Saves You Money",
          "cost_saving_section_content": "By reducing the need for human labor, our AI service can help businesses cut operational costs by 50% to 90%, depending on the scenario, while still providing exceptional customer service.",
          "ending_section_title": "Give Your Business an Edge",
          "ending_section_content": "Our professional team is dedicated to innovation and customer experience, helping your business gain an edge over the competition with cutting-edge AI solutions.",
          "restaurant": "Restaurant",
          "spa": "Spa",
          "dental_clinic": "Dental Clinic",
          "florist": "Florist",
          "footer_about": "About",
          "footer_careers": "Careers",
          "footer_contact": "Contact Us",
          "footer_rights": "All Rights Reserved",
          "testimonial_1_text": "Our customer service efficiency has skyrocketed thanks to this AI service. Highly recommend!",
          "testimonial_1_name": "John Doe, CEO of XYZ Corp",
          "testimonial_2_text": "A seamless integration with our existing systems, saving us time and money.",
          "testimonial_2_name": "Jane Smith, COO of ABC Inc."
        }
      },
      zh: {
        translation: {
          "header": "你的标志",
          "ai_service": "AI驱动的客户服务",
          "ai_service_description": "通过我们的AI驱动客户服务，革新您的客户体验。我们的先进AI能够轻松处理客户电话，提供全天候类人响应。无论是回答查询还是帮助完成任务，AI都时刻准备好提供帮助。实时聊天，确保无论在何种情况下，您的客户都能获得最佳服务。",
          "appointment_order": "AI驱动的预约和订单",
          "appointment_order_description": "通过AI驱动的预约调度和订单管理来增强您的业务操作。允许您的客户通过直观的界面无缝预订预约、订购食品并管理他们的互动。无论是通过电话还是在线，我们的AI都能确保顺畅的过程。登录我们的网站以查看、修改和监督所有客户互动。",
          "scenarios": "多场景的多功能AI",
          "scenarios_description": "我们的AI客户服务解决方案适用于各种场景，从餐厅到水疗中心，从牙科诊所到花店。无论行业如何，我们的AI都能与您现有的系统无缝集成，提供个性化、高效且一致的服务，以满足您的独特需求。",
          "integration": "无缝集成和自定义",
          "integration_description": "将我们的AI客户服务与您的现有软件集成，并根据您的品牌声音进行定制。无论您是需要连接您的CRM、管理客户数据，还是提供定制服务，我们的API都能轻松实现集成并扩展功能。您的品牌，您的规则，由我们的AI提供支持。",
          "testimonials": "客户评价和成功案例",
          "testimonials_description": "听听我们的满意客户如何通过我们的AI解决方案变革了他们的客户服务。从提高效率到增强客户满意度，我们的AI在各个行业都提供了可衡量的成果。阅读他们的故事，看看我们的AI如何为您服务。",
          "cost_saving_section_title": "我们的AI客户服务如何为您节省成本",
          "cost_saving_section_content": "通过减少对人力的需求，我们的AI服务可以帮助企业根据场景削减50%到90%的运营成本，同时仍提供卓越的客户服务。",
          "ending_section_title": "让您的业务脱颖而出",
          "ending_section_content": "我们专业的团队致力于创新和客户体验，通过先进的AI解决方案帮助您的企业在竞争中脱颖而出。",
          "restaurant": "餐厅",
          "spa": "水疗中心",
          "dental_clinic": "牙科诊所",
          "florist": "花店",
          "footer_about": "关于",
          "footer_careers": "职业",
          "footer_contact": "联系我们",
          "footer_rights": "版权所有",
          "testimonial_1_text": "由于这个AI服务，我们的客户服务效率大幅提升。强烈推荐！",
          "testimonial_1_name": "约翰·多伊, XYZ公司的CEO",
          "testimonial_2_text": "与我们现有系统的无缝集成，为我们节省了时间和金钱。",
          "testimonial_2_name": "简·史密斯, ABC公司的COO"
        }
      }
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
