// src/components/AppointmentOrder.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import './AppointmentOrder.css';

const AppointmentOrder = () => {
  const { t } = useTranslation();

  return (
    <section id="appointment-order" className="appointment-order">
      <div className="appointment-order-bg">
        <img src="/images/appointment-order-bg.jpg" alt="Background" />
      </div>
      <div className="appointment-order-content">
        <h2>{t('appointment_order')}</h2>
        <p>{t('appointment_order_description')}</p>
      </div>
    </section>
  );
};

export default AppointmentOrder;
