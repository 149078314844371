// src/components/Integration.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import './Integration.css';

const Integration = () => {
  const { t } = useTranslation();

  return (
    <section id="integration" className="integration">
      <div className="integration-content">
        <h2>{t('integration')}</h2>
        <p>{t('integration_description')}</p>
        <div className="integration-flow">
          <img src="images/integration-flow.jpg" alt="Integration Flow" />
        </div>
      </div>
    </section>
  );
};

export default Integration;
